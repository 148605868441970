import { theme } from '../theme';
export const NODE_DIMENSION = 80;
export const TILE_NODE_HEIGHT = 74;
export const TILE_NODE_WIDTH = 266;
export const GRID_SIZE = NODE_DIMENSION / 4;
export const SUBFLOW_MARGIN = 60;
export const NODE_SPACING = 120;
export const LABEL_HEIGHT = 20;
export const REACT_FLOW_CLASS = 'react-flow';
export const REACT_FLOW_PANE = 'react-flow__pane';
export const LEFT_HANDLE_CLASS = 'react-flow__handle-left';
export const RIGHT_HANDLE_CLASS = 'react-flow__handle-right';
export const REACT_FLOW_HANDLE = 'react-flow__handle';
export const REACT_FLOW_CONNECTION_PATH = 'react-flow__connection-path';
export const REACT_FLOW_EDGE_PATH = 'react-flow__edge-path';
export const REACT_FLOW_EDGE = 'react-flow__edge';
export const REACT_FLOW_CONTROLS = 'react-flow__controls';
export const REACT_FLOW_MINIMAP = 'react-flow__minimap';
export const REACT_FLOW_NODESSELECTION_RECT = 'react-flow__nodesselection-rect';
export const REACT_FLOW_HANDLE_CONNECTING = 'react-flow__handle-connecting';
export const REACT_FLOW_HANDLE_VALID = 'react-flow__handle-valid';
export const REACT_FLOW_ATTRIBUTION = 'react-flow__attribution';
export const REACT_FLOW_SELECTED = 'selected';
export const REACT_FLOW_NODE = 'react-flow__node';
export const REACT_FLOW_SELECTION_PANE = 'react-flow__selectionpane';
export const HIGHLIGHTED_NODE_HANDLE = 'highlight-node-handle';
export const AUTO_POSITION = 2147483600;
export const GEM_NODE_CONTAINER = 'gem-node-container';
export const GEM_NODE_WRAPPER = 'gem-node-wrapper';
export const REACT_FLOW_SOURCE_HANDLE_KEY = 'source';
export const ADD_NEW_PORT_HANDLE = 'add-new-port-handle';
export const ADD_NEW_PORT_HANDLE_ID = 'new-port-id';
export const REACT_FLOW_CONNECTION_LINE = 'react-flow__connectionline';
export const tokens = {
    Tile: {
        width: `${TILE_NODE_WIDTH}px`,
        height: `${TILE_NODE_HEIGHT}px`,
        transition: theme.transition.m
    },
    Node: {
        height: `${NODE_DIMENSION}px`,
        width: `${NODE_DIMENSION}px`,
        background: theme.colors.white,
        borderRadius: theme.radius.l,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.colors.grey300,
        Label: {
            gap: theme.spaces.x4
        },
        state: {
            selected: {
                borderWidth: '3px',
                borderColor: theme.colors.primary500,
                shadow: theme.shadows.xl
            },
            dragging: {
                borderWidth: '2px',
                borderStyle: 'dashed',
                borderColor: theme.colors.primary500
            },
            error: {
                borderColor: theme.colors.error500
            },
            warning: {
                borderColor: theme.colors.warning500
            },
            modified: {
                borderColor: theme.colors.yellow300,
                borderWidth: '2px'
            }
        },
        hover: {
            shadow: theme.shadows.md
        }
    },
    Selection: {
        border: `1px dashed ${theme.colors.primary500}`,
        padding: theme.spaces.x24,
        transformX: `-${theme.spaces.x24}`,
        transformY: `-${theme.spaces.x24}`
    },
    Connection: {
        Label: {
            borderRadius: theme.radius.xs,
            background: theme.colors.primary50
        },
        Path: {
            strokeWidth: theme.spaces.x2,
            stroke: theme.colors.grey300
        },
        state: {
            selected: {
                stroke: theme.colors.primary500
            },
            virtual: {
                stroke: theme.colors.grey300
            }
        }
    },
    Controls: {
        Icon: {
            borderRadius: theme.radius.xs,
            state: {
                hover: {
                    background: theme.colors.primary500,
                    color: theme.colors.white
                }
            }
        }
    },
    Status: {
        Pass: {
            borderColor: theme.colors.success400,
            color: theme.colors.success500
        },
        Modify: {
            borderColor: theme.colors.warning400,
            color: theme.colors.warning500
        },
        Absent: {
            borderColor: theme.colors.grey300,
            color: theme.colors.grey500
        }
    },
    ExtenderEdge: {
        Line: {
            borderColor: theme.colors.grey200
        }
    }
};
