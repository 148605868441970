import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { MiniCheckIcon, MiniMinusIcon } from '../Icons';
import { Stack } from '../Layout';
import { theme } from '../theme/index';
import { renderWithTooltip } from '../Tooltip';
import { LabelWithHint } from '../Typography/LabelWithHint';
import { StyledCheckboxRoot, StyledCheckboxIndicator, CheckboxLabel, StyledHelpText } from './styled';
import { Size } from './types';
export const Checkbox = (_a) => {
    var { checked, defaultChecked, indeterminate, disabled, onChange, children, size = Size.s, hint, tooltip, helpText } = _a, restProps = __rest(_a, ["checked", "defaultChecked", "indeterminate", "disabled", "onChange", "children", "size", "hint", "tooltip", "helpText"]);
    const [checkedState, setCheckedState] = useControlledState({
        value: indeterminate ? 'indeterminate' : checked,
        defaultValue: defaultChecked,
        onChange: (value) => onChange === null || onChange === void 0 ? void 0 : onChange(value)
    });
    let checkBoxUI = (_jsx(StyledCheckboxRoot, Object.assign({ checked: !!checkedState, size: size, defaultChecked: !!defaultChecked, onCheckedChange: setCheckedState, disabled: Boolean(disabled) }, restProps, { children: _jsxs(StyledCheckboxIndicator, { children: [checkedState === 'indeterminate' && _jsx(MiniMinusIcon, { type: 'mini', size: 'xxs' }), checkedState === true && _jsx(MiniCheckIcon, { type: 'mini' })] }) })));
    if (tooltip) {
        checkBoxUI = _jsx(_Fragment, { children: renderWithTooltip(checkBoxUI, tooltip) });
    }
    if (hint && !children) {
        checkBoxUI = _jsx(LabelWithHint, { hint: hint, children: checkBoxUI });
    }
    const childrenUI = hint && children ? _jsx(LabelWithHint, { hint: hint, children: children }) : children;
    return (_jsxs(Stack, { gap: theme.spaces.x2, children: [_jsxs(Stack, { direction: 'horizontal', gap: theme.spaces.x8, alignY: 'center', children: [checkBoxUI, childrenUI && (_jsx(CheckboxLabel, { level: 'sm', size: size, disabled: Boolean(disabled), onClick: () => {
                            !disabled && setCheckedState(!checkedState);
                        }, children: childrenUI }))] }), helpText && _jsx(StyledHelpText, { children: helpText })] }));
};
