import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Lottie from 'lottie-react';
import { forwardRef, useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Button } from '../Button';
import iconData from './icon';
import loadingData from './loading';
const StyledCopilotIconWrap = styled.div `
  display: inline-block;
  vertical-align: middle;
  width: ${({ dimension }) => dimension};
  height: ${({ dimension }) => dimension};
  position: relative;
`;
const StyledCopilotStillIcon = styled(Lottie) `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 1;
  ${({ loading }) => loading && `opacity: 0;`}
  transition: opacity 0.3s ease-in-out;

  ${({ disabled }) => disabled &&
    `
    filter: grayscale(1);
    opacity: 0.8;
  `}
`;
const StyledCopilotLoadingIcon = styled(Lottie) `
  position: absolute;
  width: 250%;
  height: 250%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
  opacity: 0;
  ${({ loading }) => loading &&
    `
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  `}
  transition: opacity 0.3s ease-in-out, transform 0.6s ease-in-out;
`;
const GlobalIconStyles = createGlobalStyle `
  [style="mix-blend-mode: color-dodge; display: block;"] {
    		mix-blend-mode: plus-lighter !important;    	
  }
`;
export const CopilotIcon = forwardRef((_a, ref) => {
    var { size, disabled, loading, active } = _a, rest = __rest(_a, ["size", "disabled", "loading", "active"]);
    const stillCopilotRef = useRef(null);
    const animatingCopilotRef = useRef(null);
    const [hovered, toggleHover] = useState(false);
    useEffect(() => {
        var _a, _b, _c, _d;
        (_a = stillCopilotRef.current) === null || _a === void 0 ? void 0 : _a.play();
        (_b = animatingCopilotRef.current) === null || _b === void 0 ? void 0 : _b.play();
        if ((!active && !hovered) || disabled) {
            (_c = stillCopilotRef.current) === null || _c === void 0 ? void 0 : _c.pause();
        }
        if (!loading) {
            (_d = animatingCopilotRef.current) === null || _d === void 0 ? void 0 : _d.stop();
        }
    }, [active, hovered, loading, disabled]);
    return (_jsxs(StyledCopilotIconWrap, Object.assign({}, rest, { dimension: size, ref: ref, onMouseEnter: () => toggleHover(true), onMouseLeave: () => toggleHover(false), children: [_jsx(GlobalIconStyles, {}), _jsx(StyledCopilotStillIcon, { animationData: iconData, disabled: disabled, loading: loading, lottieRef: stillCopilotRef }), !disabled && (_jsx(StyledCopilotLoadingIcon, { animationData: loadingData, loading: loading, lottieRef: animatingCopilotRef }))] })));
});
export const CopilotButton = forwardRef((_a, ref) => {
    var { size, disabled, loading, active } = _a, rest = __rest(_a, ["size", "disabled", "loading", "active"]);
    return (_jsx(Button, Object.assign({ variant: 'plain' }, rest, { ref: ref, children: _jsx(CopilotIcon, { size: size, loading: loading, active: active }) })));
});
