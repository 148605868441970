import styled from 'styled-components';
import { Icon } from '../Icons';
import { theme } from '../theme';
export const SquareIconContainer = styled.div `
  width: ${({ size }) => size !== null && size !== void 0 ? size : theme.sizes.x48};
  height: ${({ size }) => size !== null && size !== void 0 ? size : theme.sizes.x48};
  padding: ${theme.spaces.x10};
  border-radius: ${theme.radius.m};
  text-align: center;
  background: ${({ backgroundColor, from, to }) => backgroundColor ? backgroundColor : `radial-gradient(${from} 50%, ${to} 50%)`};
  ${Icon} {
    color: ${({ color }) => color};
  }
`;
