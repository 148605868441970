const json = {
    v: '5.9.0',
    fr: 30,
    ip: 0,
    op: 420,
    w: 540,
    h: 540,
    nm: 'Main 5',
    ddd: 0,
    assets: [
        {
            id: 'comp_0',
            nm: 'Outer',
            fr: 30,
            layers: [
                {
                    ddd: 0,
                    ind: 1,
                    ty: 4,
                    nm: 'Shape Layer 3',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 17, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [887.895, 765.75, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [-74.605, -196.75, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [106.804, 106.804, 100], ix: 6, l: 2 }
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    d: 1,
                                    ty: 'el',
                                    s: { a: 0, k: [470.309, 470.309], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    nm: 'Ellipse Path 1',
                                    mn: 'ADBE Vector Shape - Ellipse',
                                    hd: false
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0.058824000639, 0.486274988511, 0.592156982422, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 0, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 3,
                                        k: {
                                            a: 0,
                                            k: [0.568, 1, 1, 1, 0.834, 1, 1, 1, 1, 1, 1, 1, 0.565, 0, 0.86, 0.35, 1, 0.7],
                                            ix: 9
                                        }
                                    },
                                    s: { a: 0, k: [0, 0], ix: 5 },
                                    e: { a: 0, k: [233.584, -8.627], ix: 6 },
                                    t: 2,
                                    h: { a: 0, k: 0, ix: 7 },
                                    a: { a: 0, k: 0, ix: 8 },
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 3,
                                        k: {
                                            a: 0,
                                            k: [0.568, 1, 1, 1, 0.834, 1, 1, 1, 1, 1, 1, 1, 0.565, 0, 0.86, 0.35, 1, 0.7],
                                            ix: 9
                                        }
                                    },
                                    s: { a: 0, k: [0, 0], ix: 5 },
                                    e: { a: 0, k: [233.584, -8.627], ix: 6 },
                                    t: 2,
                                    h: { a: 0, k: 0, ix: 7 },
                                    a: { a: 0, k: 0, ix: 8 },
                                    nm: 'Gradient Fill 2',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [-74.605, -196.75], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform'
                                }
                            ],
                            nm: 'Ellipse 1',
                            np: 4,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false
                        }
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 5
                },
                {
                    ddd: 0,
                    ind: 2,
                    ty: 4,
                    nm: 'Shape Layer 4',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 17, ix: 11 },
                        r: { a: 0, k: 0, ix: 10 },
                        p: { a: 0, k: [887.895, 765.75, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [-74.605, -196.75, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [106.804, 106.804, 100], ix: 6, l: 2 }
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    d: 1,
                                    ty: 'el',
                                    s: { a: 0, k: [470.309, 470.309], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    nm: 'Ellipse Path 1',
                                    mn: 'ADBE Vector Shape - Ellipse',
                                    hd: false
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0.058824000639, 0.486274988511, 0.592156982422, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 0, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 3,
                                        k: {
                                            a: 0,
                                            k: [0.827, 1, 1, 1, 0.906, 1, 1, 1, 1, 1, 1, 1, 0.827, 0, 0.953, 0.35, 1, 0.7],
                                            ix: 9
                                        }
                                    },
                                    s: { a: 0, k: [0, 0], ix: 5 },
                                    e: { a: 0, k: [237.034, -3.659], ix: 6 },
                                    t: 2,
                                    h: { a: 0, k: 0, ix: 7 },
                                    a: { a: 0, k: 0, ix: 8 },
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 3,
                                        k: {
                                            a: 0,
                                            k: [0.827, 1, 1, 1, 0.906, 1, 1, 1, 1, 1, 1, 1, 0.827, 0, 0.953, 0.35, 1, 0.7],
                                            ix: 9
                                        }
                                    },
                                    s: { a: 0, k: [0, 0], ix: 5 },
                                    e: { a: 0, k: [237.034, -3.659], ix: 6 },
                                    t: 2,
                                    h: { a: 0, k: 0, ix: 7 },
                                    a: { a: 0, k: 0, ix: 8 },
                                    nm: 'Gradient Fill 2',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [-74.605, -196.75], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform'
                                }
                            ],
                            nm: 'Ellipse 1',
                            np: 4,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false
                        }
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 5
                },
                {
                    ddd: 0,
                    ind: 3,
                    ty: 4,
                    nm: 'Layer 4',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 60, ix: 11 },
                        r: {
                            a: 1,
                            k: [
                                { i: { x: [0.51], y: [1] }, o: { x: [0.167], y: [0] }, t: 0, s: [0] },
                                { t: 179, s: [0] }
                            ],
                            ix: 10
                        },
                        p: { a: 0, k: [887.552, 766.054, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [-222.541, 74.098, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [258.246, 258.246, 100], ix: 6, l: 2 }
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    d: 1,
                                    ty: 'el',
                                    s: { a: 0, k: [193.197, 193.197], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    nm: 'Ellipse Path 1',
                                    mn: 'ADBE Vector Shape - Ellipse',
                                    hd: false
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0.058824000639, 0.486274988511, 0.592156982422, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 0, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 3,
                                        k: {
                                            a: 0,
                                            k: [0.827, 1, 1, 1, 0.906, 1, 1, 1, 1, 1, 1, 1, 0.827, 0, 0.953, 0.35, 1, 0.7],
                                            ix: 9
                                        }
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-42.684, -33.051],
                                                to: [0.697, -5.29],
                                                ti: [-1.759, 13.361]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 51.381,
                                                s: [-39.387, -101.528],
                                                to: [1.116, -8.475],
                                                ti: [0, 0]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 68.407,
                                                s: [-35.853, -84.928],
                                                to: [0, 0],
                                                ti: [1.749, -13.284]
                                            },
                                            {
                                                i: { x: 0.51, y: 1 },
                                                o: { x: 0.167, y: 0.166 },
                                                t: 98.531,
                                                s: [8.169, -95.115],
                                                to: [-1.413, 10.733],
                                                ti: [-1.139, 8.646]
                                            },
                                            { t: 179, s: [-42.684, -33.051] }
                                        ],
                                        ix: 5
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [110.946, 1.22],
                                                to: [9.312, 31.136],
                                                ti: [0, 0]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 68.407,
                                                s: [166.821, 188.038],
                                                to: [0, 0],
                                                ti: [16.356, 54.685]
                                            },
                                            {
                                                i: { x: 0.51, y: 1 },
                                                o: { x: 0.167, y: 0.165 },
                                                t: 98.531,
                                                s: [-7.348, 93.011],
                                                to: [-3.518, -11.761],
                                                ti: [-19.716, 15.299]
                                            },
                                            { t: 179, s: [110.946, 1.22] }
                                        ],
                                        ix: 6
                                    },
                                    t: 2,
                                    h: { a: 0, k: 0, ix: 7 },
                                    a: { a: 0, k: 0, ix: 8 },
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [-222.541, 74.098], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform'
                                }
                            ],
                            nm: 'Ellipse 1',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false
                        }
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 9
                },
                {
                    ddd: 0,
                    ind: 4,
                    ty: 4,
                    nm: 'Layer 5',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: {
                            a: 1,
                            k: [
                                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                                { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 39.207, s: [28] },
                                { i: { x: [0.51], y: [1] }, o: { x: [0.167], y: [0] }, t: 68.407, s: [0] },
                                { t: 179, s: [0] }
                            ],
                            ix: 10
                        },
                        p: { a: 0, k: [886.879, 765.681, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [-221.443, 74.098, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [258.246, 258.246, 100], ix: 6, l: 2 }
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    d: 1,
                                    ty: 'el',
                                    s: { a: 0, k: [193.197, 193.197], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    nm: 'Ellipse Path 1',
                                    mn: 'ADBE Vector Shape - Ellipse',
                                    hd: false
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0.058824000639, 0.486274988511, 0.592156982422, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 0, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 11,
                                        k: {
                                            a: 0,
                                            k: [
                                                0.021, 0.376, 0.816, 0.918, 0.13, 0.2, 0.763, 0.875, 0.24, 0.024, 0.71, 0.831, 0.323, 0.512,
                                                0.531, 0.416, 0.406, 1, 0.352, 0, 0.479, 0.992, 0.413, 0.039, 0.551, 0.984, 0.475, 0.078, 0.674,
                                                0.984, 0.481, 0.078, 0.797, 0.984, 0.488, 0.078, 0.898, 0.984, 0.499, 0.078, 1, 0.984, 0.51,
                                                0.078, 0.021, 0, 0.13, 0, 0.24, 0, 0.33, 0, 0.42, 0, 0.487, 0, 0.554, 0, 0.674, 0.5, 0.795, 1,
                                                0.897, 1, 1, 1
                                            ],
                                            ix: 9
                                        }
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-42.046, -22.923],
                                                to: [-2.642, -3.958],
                                                ti: [-1.564, 8.304]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 15,
                                                s: [-16.061, -12.011],
                                                to: [1.962, -10.418],
                                                ti: [9.12, 13.665]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 39.207,
                                                s: [-46.979, -78.946],
                                                to: [-25.565, -38.306],
                                                ti: [0, 0]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 68.407,
                                                s: [36.398, -103.746],
                                                to: [0, 0],
                                                ti: [27.039, -25.73]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 98.917,
                                                s: [60.742, -159.967],
                                                to: [-4.094, 3.896],
                                                ti: [5.218, -5.614]
                                            },
                                            {
                                                i: { x: 0.51, y: 1 },
                                                o: { x: 0.167, y: 0.166 },
                                                t: 114.248,
                                                s: [-96.478, -145.136],
                                                to: [-22.614, 24.33],
                                                ti: [12.388, 18.562]
                                            },
                                            { t: 179, s: [-42.046, -22.923] }
                                        ],
                                        ix: 5
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [81.589, 82.358],
                                                to: [1.463, 2.293],
                                                ti: [14.178, -6.169]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 15,
                                                s: [52.752, 174.545],
                                                to: [-7.358, 3.201],
                                                ti: [-4.282, -6.709]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 39.207,
                                                s: [66.006, 174.22],
                                                to: [16.217, 25.412],
                                                ti: [0, 0]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 68.407,
                                                s: [-99.147, 137.366],
                                                to: [0, 0],
                                                ti: [-2.824, -2.259]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 98.917,
                                                s: [89.244, 180.753],
                                                to: [1.728, 1.382],
                                                ti: [-22.164, 10.327]
                                            },
                                            {
                                                i: { x: 0.51, y: 1 },
                                                o: { x: 0.167, y: 0.166 },
                                                t: 114.248,
                                                s: [132.166, 178.264],
                                                to: [57.137, -26.622],
                                                ti: [-6.968, -10.92]
                                            },
                                            { t: 179, s: [81.589, 82.358] }
                                        ],
                                        ix: 6
                                    },
                                    t: 2,
                                    h: { a: 0, k: 0, ix: 7 },
                                    a: { a: 0, k: 0, ix: 8 },
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [-221.443, 74.098], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform'
                                }
                            ],
                            nm: 'Ellipse 1',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false
                        }
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0
                },
                {
                    ddd: 0,
                    ind: 5,
                    ty: 4,
                    nm: 'Layer 3',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: {
                            a: 1,
                            k: [
                                { i: { x: [0.51], y: [1] }, o: { x: [0.167], y: [0] }, t: 0, s: [0] },
                                { t: 179, s: [0] }
                            ],
                            ix: 10
                        },
                        p: { a: 0, k: [886.81, 766.054, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [-222.035, 74.098, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [258.246, 258.246, 100], ix: 6, l: 2 }
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    d: 1,
                                    ty: 'el',
                                    s: { a: 0, k: [193.197, 193.197], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    nm: 'Ellipse Path 1',
                                    mn: 'ADBE Vector Shape - Ellipse',
                                    hd: false
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0.058824000639, 0.486274988511, 0.592156982422, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 0, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 3,
                                        k: {
                                            a: 0,
                                            k: [0.568, 1, 1, 1, 0.834, 1, 1, 1, 1, 1, 1, 1, 0.565, 0, 0.86, 0.35, 1, 0.7],
                                            ix: 9
                                        }
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-54.131, -51.262],
                                                to: [10.672, -5.551],
                                                ti: [-7.92, 0.912]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 15,
                                                s: [-17.398, -41.466],
                                                to: [4.411, -0.508],
                                                ti: [-16.357, 8.508]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 39.207,
                                                s: [-10.63, -63.021],
                                                to: [71.323, -37.097],
                                                ti: [0, 0]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 68.407,
                                                s: [142.116, 24.684],
                                                to: [0, 0],
                                                ti: [80.128, -35.185]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 82.814,
                                                s: [271.798, 39.226],
                                                to: [-27.778, 12.198],
                                                ti: [16.825, -8.751]
                                            },
                                            {
                                                i: { x: 0.51, y: 1 },
                                                o: { x: 0.167, y: 0.166 },
                                                t: 98.917,
                                                s: [-42.903, 247.654],
                                                to: [-52.792, 27.459],
                                                ti: [-42.536, 22.124]
                                            },
                                            { t: 179, s: [-54.131, -51.262] }
                                        ],
                                        ix: 5
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [108.144, 13.448],
                                                to: [-3.301, -0.314],
                                                ti: [4.538, -14.218]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 15,
                                                s: [85.958, 134.726],
                                                to: [-7.355, 23.047],
                                                ti: [5.147, 0.489]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 39.207,
                                                s: [75.549, 91.706],
                                                to: [-19.712, -1.874],
                                                ti: [0.054, -0.218]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 68.407,
                                                s: [-165.759, 125.324],
                                                to: [-0.518, 2.077],
                                                ti: [-61.862, 60.719]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 82.779,
                                                s: [-190.205, -237.133],
                                                to: [84.243, -82.685],
                                                ti: [-13.041, -1.24]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 98.917,
                                                s: [147.395, -165.449],
                                                to: [11.156, 1.061],
                                                ti: [-52.732, 30.381]
                                            },
                                            {
                                                i: { x: 0.51, y: 1 },
                                                o: { x: 0.167, y: 0.165 },
                                                t: 114.248,
                                                s: [218.347, 2.004],
                                                to: [31.688, -18.257],
                                                ti: [29.887, 2.841]
                                            },
                                            { t: 179, s: [108.144, 13.448] }
                                        ],
                                        ix: 6
                                    },
                                    t: 2,
                                    h: { a: 0, k: 0, ix: 7 },
                                    a: { a: 0, k: 0, ix: 8 },
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [-222.035, 74.098], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform'
                                }
                            ],
                            nm: 'Ellipse 1',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false
                        }
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0
                },
                {
                    ddd: 0,
                    ind: 6,
                    ty: 4,
                    nm: 'Layer 2',
                    sr: 1,
                    ks: {
                        o: { a: 0, k: 100, ix: 11 },
                        r: {
                            a: 1,
                            k: [
                                { i: { x: [0.51], y: [1] }, o: { x: [0.167], y: [0] }, t: 0, s: [0] },
                                { t: 179, s: [0] }
                            ],
                            ix: 10
                        },
                        p: { a: 0, k: [886.879, 765.681, 0], ix: 2, l: 2 },
                        a: { a: 0, k: [-221.443, 74.098, 0], ix: 1, l: 2 },
                        s: { a: 0, k: [258.246, 258.246, 100], ix: 6, l: 2 }
                    },
                    ao: 0,
                    shapes: [
                        {
                            ty: 'gr',
                            it: [
                                {
                                    d: 1,
                                    ty: 'el',
                                    s: { a: 0, k: [193.197, 193.197], ix: 2 },
                                    p: { a: 0, k: [0, 0], ix: 3 },
                                    nm: 'Ellipse Path 1',
                                    mn: 'ADBE Vector Shape - Ellipse',
                                    hd: false
                                },
                                {
                                    ty: 'st',
                                    c: { a: 0, k: [0.058824000639, 0.486274988511, 0.592156982422, 1], ix: 3 },
                                    o: { a: 0, k: 100, ix: 4 },
                                    w: { a: 0, k: 0, ix: 5 },
                                    lc: 1,
                                    lj: 1,
                                    ml: 4,
                                    bm: 0,
                                    nm: 'Stroke 1',
                                    mn: 'ADBE Vector Graphic - Stroke',
                                    hd: false
                                },
                                {
                                    ty: 'gf',
                                    o: { a: 0, k: 100, ix: 10 },
                                    r: 1,
                                    bm: 0,
                                    g: {
                                        p: 11,
                                        k: {
                                            a: 0,
                                            k: [
                                                0.021, 0.544, 0.895, 0.977, 0.13, 0.284, 0.803, 0.904, 0.24, 0.024, 0.71, 0.831, 0.344, 0.173,
                                                0.612, 0.855, 0.448, 0.322, 0.514, 0.878, 0.534, 0.31, 0.408, 0.871, 0.62, 0.298, 0.302, 0.863,
                                                0.727, 0.641, 0.404, 0.471, 0.833, 0.984, 0.506, 0.078, 0.917, 0.984, 0.457, 0.078, 1, 0.984,
                                                0.408, 0.078, 0.021, 1, 0.13, 0.935, 0.239, 0.87, 0.343, 0.935, 0.448, 1, 0.534, 1, 0.62, 1,
                                                0.727, 1, 0.833, 1, 0.917, 1, 1, 1
                                            ],
                                            ix: 9
                                        }
                                    },
                                    s: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [-41.91, -32.652],
                                                to: [2.298, -2.97],
                                                ti: [-20.351, 19.861]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 15,
                                                s: [-11.244, -27.231],
                                                to: [7.075, -6.904],
                                                ti: [-6.376, 8.24]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 39.207,
                                                s: [268.235, -101.606],
                                                to: [23.24, -30.032],
                                                ti: [0, 0]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 68.407,
                                                s: [62.848, -54.594],
                                                to: [0, 0],
                                                ti: [21.297, -27.521]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 98.917,
                                                s: [-72.628, -173.218],
                                                to: [-4.063, 5.251],
                                                ti: [17.666, -3.037]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 114.248,
                                                s: [-106.187, -169.293],
                                                to: [-29.417, 5.057],
                                                ti: [-23.034, -35.467]
                                            },
                                            {
                                                i: { x: 0.51, y: 1 },
                                                o: { x: 0.167, y: 0.166 },
                                                t: 132,
                                                s: [-214.256, -210.554],
                                                to: [21.693, 33.402],
                                                ti: [-5.134, 6.635]
                                            },
                                            { t: 179, s: [-41.91, -32.652] }
                                        ],
                                        ix: 5
                                    },
                                    e: {
                                        a: 1,
                                        k: [
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 0,
                                                s: [110.946, 1.22],
                                                to: [-0.076, 2.417],
                                                ti: [53.165, -51.786]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 15,
                                                s: [60.577, 200.943],
                                                to: [-36.216, 35.276],
                                                ti: [19.774, -2.973]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 23.876,
                                                s: [-87.549, 186.471],
                                                to: [-48.807, 7.338],
                                                ti: [0.354, -11.183]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 39.207,
                                                s: [-209.575, 51.076],
                                                to: [-1.103, 34.886],
                                                ti: [0, 0]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 68.407,
                                                s: [-148.384, 103.05],
                                                to: [0, 0],
                                                ti: [-31.835, 23.54]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 82.814,
                                                s: [-202.03, -11.74],
                                                to: [7.797, -5.765],
                                                ti: [-5.607, -0.456]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 98.917,
                                                s: [-138.572, 102.36],
                                                to: [5.823, 0.473],
                                                ti: [-14.525, 0.466]
                                            },
                                            {
                                                i: { x: 0.833, y: 0.833 },
                                                o: { x: 0.167, y: 0.167 },
                                                t: 114.248,
                                                s: [4.87, 223.357],
                                                to: [21.965, -0.705],
                                                ti: [-18.919, 56.554]
                                            },
                                            {
                                                i: { x: 0.51, y: 1 },
                                                o: { x: 0.167, y: 0.165 },
                                                t: 132,
                                                s: [265.053, 76.656],
                                                to: [18.028, -53.892],
                                                ti: [-9.94, 0.407]
                                            },
                                            { t: 179, s: [110.946, 1.22] }
                                        ],
                                        ix: 6
                                    },
                                    t: 2,
                                    h: { a: 0, k: 0.513, ix: 7 },
                                    a: { a: 0, k: -14.615, ix: 8 },
                                    nm: 'Gradient Fill 1',
                                    mn: 'ADBE Vector Graphic - G-Fill',
                                    hd: false
                                },
                                {
                                    ty: 'tr',
                                    p: { a: 0, k: [-221.443, 74.098], ix: 2 },
                                    a: { a: 0, k: [0, 0], ix: 1 },
                                    s: { a: 0, k: [100, 100], ix: 3 },
                                    r: { a: 0, k: 0, ix: 6 },
                                    o: { a: 0, k: 100, ix: 7 },
                                    sk: { a: 0, k: 0, ix: 4 },
                                    sa: { a: 0, k: 0, ix: 5 },
                                    nm: 'Transform'
                                }
                            ],
                            nm: 'Ellipse 1',
                            np: 3,
                            cix: 2,
                            bm: 0,
                            ix: 1,
                            mn: 'ADBE Vector Group',
                            hd: false
                        }
                    ],
                    ip: 0,
                    op: 210,
                    st: 0,
                    bm: 0
                }
            ]
        }
    ],
    layers: [
        {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Layer 8',
            sr: 1,
            ks: {
                o: { a: 0, k: 5, ix: 11 },
                r: { a: 0, k: 11, ix: 10 },
                p: { a: 0, k: [268.003, 270.243, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-225.541, 70.098, 0], ix: 1, l: 2 },
                s: { a: 0, k: [292.898, 283.498, 100], ix: 6, l: 2 }
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [193.197, 193.197], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.058824000639, 0.486274988511, 0.592156982422, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 0, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false
                        },
                        {
                            ty: 'gf',
                            o: { a: 0, k: 100, ix: 10 },
                            r: 1,
                            bm: 0,
                            g: {
                                p: 5,
                                k: {
                                    a: 0,
                                    k: [
                                        0.385, 0.836, 0.917, 0.942, 0.592, 0.918, 0.958, 0.971, 0.799, 1, 1, 1, 0.899, 1, 1, 1, 1, 1, 1, 1,
                                        0.389, 0, 0.527, 0.36, 0.789, 0.72, 0.909, 0.525, 1, 0.33
                                    ],
                                    ix: 9
                                }
                            },
                            s: { a: 0, k: [6.996, -35.064], ix: 5 },
                            e: { a: 0, k: [104.274, 181.341], ix: 6 },
                            t: 2,
                            h: { a: 0, k: 0, ix: 7 },
                            a: { a: 0, k: 0, ix: 8 },
                            nm: 'Gradient Fill 1',
                            mn: 'ADBE Vector Graphic - G-Fill',
                            hd: false
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [-225.541, 70.098], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [88.334, 91.051], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform'
                        }
                    ],
                    nm: 'Ellipse 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false
                }
            ],
            ip: 0,
            op: 426,
            st: 0,
            bm: 6
        },
        {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Layer 6',
            sr: 1,
            ks: {
                o: { a: 0, k: 26, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [268.552, 269.554, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-222.541, 74.098, 0], ix: 1, l: 2 },
                s: { a: 0, k: [258.246, 258.246, 100], ix: 6, l: 2 }
            },
            ao: 0,
            shapes: [
                {
                    ty: 'gr',
                    it: [
                        {
                            d: 1,
                            ty: 'el',
                            s: { a: 0, k: [193.197, 193.197], ix: 2 },
                            p: { a: 0, k: [0, 0], ix: 3 },
                            nm: 'Ellipse Path 1',
                            mn: 'ADBE Vector Shape - Ellipse',
                            hd: false
                        },
                        {
                            ty: 'st',
                            c: { a: 0, k: [0.058824000639, 0.486274988511, 0.592156982422, 1], ix: 3 },
                            o: { a: 0, k: 100, ix: 4 },
                            w: { a: 0, k: 0, ix: 5 },
                            lc: 1,
                            lj: 1,
                            ml: 4,
                            bm: 0,
                            nm: 'Stroke 1',
                            mn: 'ADBE Vector Graphic - Stroke',
                            hd: false
                        },
                        {
                            ty: 'gf',
                            o: { a: 0, k: 100, ix: 10 },
                            r: 1,
                            bm: 0,
                            g: {
                                p: 3,
                                k: {
                                    a: 0,
                                    k: [0, 1, 1, 1, 0.171, 0.918, 0.958, 0.971, 0.341, 0.836, 0.917, 0.942, 0, 1, 0.171, 0.5, 0.343, 0],
                                    ix: 9
                                }
                            },
                            s: { a: 0, k: [-57.202, -54.369], ix: 5 },
                            e: { a: 0, k: [199.162, 117.662], ix: 6 },
                            t: 2,
                            h: { a: 0, k: 0, ix: 7 },
                            a: { a: 0, k: 0, ix: 8 },
                            nm: 'Gradient Fill 1',
                            mn: 'ADBE Vector Graphic - G-Fill',
                            hd: false
                        },
                        {
                            ty: 'tr',
                            p: { a: 0, k: [-222.541, 74.098], ix: 2 },
                            a: { a: 0, k: [0, 0], ix: 1 },
                            s: { a: 0, k: [100, 100], ix: 3 },
                            r: { a: 0, k: 0, ix: 6 },
                            o: { a: 0, k: 100, ix: 7 },
                            sk: { a: 0, k: 0, ix: 4 },
                            sa: { a: 0, k: 0, ix: 5 },
                            nm: 'Transform'
                        }
                    ],
                    nm: 'Ellipse 1',
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false
                }
            ],
            ip: 0,
            op: 426,
            st: 0,
            bm: 3
        },
        {
            ddd: 0,
            ind: 5,
            ty: 0,
            nm: 'Outer',
            refId: 'comp_0',
            sr: 1,
            ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [190, 322, 0], ix: 2, l: 2 },
                a: { a: 0, k: [808.5, 818.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
            },
            ao: 0,
            tm: {
                a: 1,
                k: [
                    { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                    { t: 420, s: [6.029] }
                ],
                ix: 2
            },
            w: 1617,
            h: 1637,
            ip: 0,
            op: 454,
            st: 0,
            bm: 0
        }
    ],
    markers: []
};
export default json;
