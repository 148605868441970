import { Root, Thumb } from '@radix-ui/react-switch';
import styled from 'styled-components';
import { Button } from '../Button';
import { Icon } from '../Icons';
import { Stack } from '../Layout';
import { BaseColor, getBoxShadow, theme } from '../theme/index';
import { tokens } from './tokens';
export const StyledSwitchContainer = styled(Stack) `
  ${(props) => (props.disabled ? `cursor: not-allowed;` : `cursor: pointer;`)};
`;
export const StyledSwitchRoot = styled(Root) `
  all: unset;
  align-items: center;
  ${({ size }) => `
    width: ${tokens.size[size].width};
    height: ${tokens.size[size].height};
  `};
  padding: ${tokens.SwitchRoot.padding} 0;
  background-color: ${tokens.SwitchRoot.state.normal.backgroundColor};
  border-radius: ${tokens.SwitchRoot.borderRadius};
  position: relative;
  flex-shrink: 0;
  ${({ disabled }) => disabled
    ? `
      background-color: ${tokens.SwitchRoot.state.normal.backgroundColor};
      cursor: not-allowed;
   `
    : `
    &:hover,  &:focus{
      background-color: ${tokens.SwitchRoot.state.normal.hover.backgroundColor};
    }
    &[data-state='checked'] {
      background-color: ${tokens.SwitchRoot.state.selected.backgroundColor};
      &:hover{
        background-color: ${tokens.SwitchRoot.state.selected.hover.backgroundColor};
      }
      &:focus{
       box-shadow: ${getBoxShadow(BaseColor.primary, 100)};
        background-color: ${tokens.SwitchRoot.state.selected.focus.backgroundColor};
      }
     }
  `}

  ${(props) => props.hasHelpText && `top: ${theme.spaces.x4};`}
`;
export const StyledSwitchThumb = styled(Thumb) `
  display: block;
  ${({ size }) => `
    width: ${tokens.size[size].SwitchThumb.size};
    height: ${tokens.size[size].SwitchThumb.size};
  `};
  background-color: ${({ disabled }) => disabled ? tokens.SwitchThumb.disabled.backgroundColor : tokens.SwitchThumb.backgroundColor};
  border-radius: 100%;
  transition: transform ${theme.transition.s};
  transform: translateX(${tokens.SwitchRoot.padding});
  will-change: transform;
  filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.06)) drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1));
  &[data-state='checked'] {
    transform: translateX(18px);
  }
`;
export const StyledToggleSwitchRoot = styled(Root) `
  display: flex;
  align-items: center;
  padding: 0;
  background-color: ${({ switchVariant }) => tokens.ToggleSwitch.variant[switchVariant].backgroundColor};
  border-radius: ${tokens.ToggleSwitch.borderRadius};
  border: none;
  gap: ${tokens.ToggleSwitch.gap};
  position: relative;
  ${({ disabled }) => disabled
    ? `
      cursor: not-allowed;
   `
    : ''}
`;
export const StyledSwitchButton = styled(Button) `
  padding: 0 ${tokens.ToggleSwitch.padding};
  font-weight: ${tokens.ToggleSwitch.fontWeight};
  background: ${({ checked, switchVariant }) => checked
    ? tokens.ToggleSwitch.activeState.backgroundColor
    : tokens.ToggleSwitch.variant[switchVariant].backgroundColor};
  border-radius: ${tokens.ToggleSwitch.borderRadius};
  border: 1px solid transparent;
  min-width: 75px;
  width: 50%;
  ${Icon}, & {
    font-size: ${({ switchSize }) => tokens.ToggleSwitch.size[switchSize].fontSize};
    color: ${({ checked }) => (checked ? tokens.ToggleSwitch.activeState.color : tokens.ToggleSwitch.color)};
  }
  ${Icon} {
    font-size: ${({ switchSize }) => tokens.ToggleSwitch.iconSize};
  }
  ${({ switchSize, checked, disabled }) => `
    height: ${tokens.ToggleSwitch.size[switchSize].height};
    ${checked
    ? `
    border-color: ${disabled ? tokens.ToggleSwitch.disabledState.borderColor : tokens.ToggleSwitch.activeState.borderColor};
    box-shadow: ${disabled ? 'none' : tokens.ToggleSwitch.activeState.boxShadow};
    ${Icon}, &{
      color: ${disabled ? tokens.ToggleSwitch.disabledState.color : tokens.ToggleSwitch.activeState.color};
    }
    `
    : ''}
    ${disabled
    ? `
      cursor: not-allowed;
      ${Icon}, &{
        color: ${tokens.ToggleSwitch.disabledState.color};
      }
    `
    : `
      &:hover, &:focus{
        ${Icon}, &{
         color: ${checked ? tokens.ToggleSwitch.hoverState.activeState.color : tokens.ToggleSwitch.hoverState.color};
        }
        
      }
    `}
 
  `};
`;
